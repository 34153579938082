import React, { useEffect, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import { gsap } from "gsap"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { ScrollScene } from "scrollscene"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"

const getContactContent = graphql`
  {
    heroBgImg: allWordpressWpHero {
      edges {
        node {
          acf {
            contact_hero {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
    bgImg: file(relativePath: { eq: "m7.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const ContactPage = () => {
  const { heroBgImg, bgImg } = useStaticQuery(getContactContent)
  const contactHeroImg =
    heroBgImg.edges[0].node.acf.contact_hero.localFile.childImageSharp.fluid
  const bgImage = bgImg.childImageSharp.fluid
  let headerContainer = useRef(null)
  let headerLogo = useRef(null)
  let headerMenu = useRef(null)

  useEffect(() => {
    //GSAP Animations
    const headerAnimation = gsap.timeline()

    headerAnimation
      .set(headerContainer.current, {
        visibility: "visible",
      })
      .from([headerLogo.current, headerMenu.current], {
        duration: 0.5,
        y: 10,
        autoAlpha: 0,
        ease: "power3.inOut",
        stagger: 0.2,
        delay: 2,
      })
  }, [])

  return (
    <Layout
      ref={{
        headerLogo: headerLogo,
        headerMenu: headerMenu,
        headerContainer: headerContainer,
      }}
    >
      <SEO title="Contact Me" />
      <Hero
        heroTitle={`Contact Me`}
        heroSubtitle={`Let's build something amazing`}
        heroBgImg={contactHeroImg}
        bgImg={bgImage}
      />
      <section className="section-contact fixed-width-container">
        <div className="contact">
          <form
            className="contact__form"
            action="https://formspree.io/mvoagqor"
            method="POST"
          >
            <div className="contact__group">
              <input
                type="text"
                className="contact__input"
                name="name"
                id="name"
                placeholder="Name"
              />
            </div>
            <div className="contact__group">
              <input
                type="email"
                className="contact__input"
                name="email"
                id="email"
                placeholder="Email"
              />
            </div>
            <div className="contact__group">
              <textarea
                type="text"
                className="contact__textarea"
                name="message"
                id="message"
                rows="10"
                placeholder="Message"
              />
            </div>
            <button type="submit" className="contact__submit">
              Submit
            </button>
          </form>
        </div>
      </section>
    </Layout>
  )
}
export default ContactPage
